<template>
  <div v-if="true">
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        md="8"
      >
        <v-card>
          <v-subheader>用户</v-subheader>

          <v-list two-line>
            <template>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>lock</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>修改密码</v-list-item-title>
                  <!-- <v-list-item-subtitle>密码</v-list-item-subtitle> -->
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon @click="changePassword">edit</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-divider inset/>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>email</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>机构管理员邮箱</v-list-item-title>
                  <v-list-item-subtitle>{{ emailMasked }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <!-- <v-icon>edit</v-icon> -->
                </v-list-item-icon>
              </v-list-item>

              <v-divider inset/>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>phone</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>机构管理员手机</v-list-item-title>
                  <v-list-item-subtitle>{{ mobileMasked }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <!-- <v-icon>edit</v-icon> -->
                </v-list-item-icon>
              </v-list-item>

              <v-divider inset/>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon>attach_money</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>机构支付宝</v-list-item-title>
                  <v-list-item-subtitle>{{ mobileMasked }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon>
                  <!-- <v-icon>edit</v-icon> -->
                </v-list-item-icon>
              </v-list-item>

            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import AuthenticationService from '@/services/AuthenticationService'
import OrganizationService from '@/services/OrganizationService'
import consola from 'consola'
import util from '@/util'
// import moment from 'moment'

export default {
  components: {
  },
  computed: {
    mobileMasked: function () {
      if (this.organizationInfo.owner_mobile) {
        return util.maskString({ str: this.organizationInfo.owner_mobile })
      } else {
        return null
      }
    },
    emailMasked: function () {
      if (this.organizationInfo.owner_email) {
        return util.maskString({ str: this.organizationInfo.owner_email })
      } else {
        return null
      }
    },
    alipayAccountMasked: function () {
      if (this.organizationInfo.alipay_account) {
        return util.maskString({ str: this.organizationInfo.alipay_account })
      } else {
        return null
      }
    }
  },
  data () {
    return {
      user: { mobile: '', email: '' },
      organizationId: {},
      organizationInfo: {}
    }
  },

  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)
    consola.info(`page: ${this.$route.name}`)
    // const res = await AuthenticationService.getUserInfo()
    // this.user = res.data.user

    this.organizationId = this.$store.state.account.organizations[0].id

    // get organization info
    const resOrg = await OrganizationService.getOrganizationInfo({ organizationId: this.organizationId })
    this.organizationInfo = resOrg.data

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },

  methods: {
    changePassword () {
      this.$router.push({
        name: 'password-change'
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
